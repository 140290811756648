<template>
  <v-container>
    <v-form
      ref="boniForm"
      dense
      lazy-validation
    >
      <v-card
        :outlined="outlined"
        elevation="0"
      >
        <v-container>
          <v-row
            no-gutters
            class="mb-2"
          >
            <span
              style="font-size: 20px; font-weight: normal;"
            >
              Produtos bonificados
            </span>
          </v-row>
          <v-row
            no-gutters
          >
            <v-col
              cols="7"
              class="mr-4"
            >
              <v-autocomplete
                v-model="produtos_bonificado"
                :items="returnFilterProduto"
                :filter="filterProduto"
                item-text="nome"
                item-value="id"
                return-object
                multiple
                deletable-chips
                label="Selecione produtos para serem bonificados*"
                no-data-text="Não há produtos disponíveis"
                clearable
                autocomplete="off"
                small-chips
                outlined
                dense
                hide-details
                chips
                rounded
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index < 5"
                    rounded
                    small
                    class="caption"
                  >
                    <span>
                      {{ item.nome }}
                    </span>
                  </v-chip>
                  <span
                    v-if="index === 5"
                    class="grey--text caption"
                  >
                    (+{{ escolheProdutos[indexRegra].length - 5 }} outros)
                  </span>
                </template>
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                    <v-list-item-subtitle>{{ data.item.codigo }}</v-list-item-subtitle>
                    <span
                      v-if="data.item.disabled"
                      color="red"
                    ><b><i>Esse produto já está cadastrado em uma
                      campanha/oferta</i></b></span>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <v-btn
                :disabled="!produtos_bonificado"
                small
                fab
                rounded
                color="success"
                title="Adiciona os produtos selecionados a regra."
                class="text-none text-white"
                @click="addProdutoBonificado()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="value.length">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    Ações
                  </th>
                  <th>
                    Código
                  </th>
                  <th>
                    Nome
                  </th>
                  <th>
                    Quantidade
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(produto, index) in value"
                  :key="index"
                >
                  <td>
                    <v-icon
                      color="error"
                      class="my-auto"
                      @click="delProduto(index)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                  <td>
                    {{ produto.codigo }}
                  </td>
                  <td>
                    {{ produto.nome }}
                  </td>
                  <td style="">
                    <v-text-field
                      v-model="produto.quantidade"
                      v-mask="'####'"
                      style="width: 100px;"
                      outlined
                      dense
                      :rules="[rules.required, rules.geq(1)]"
                      height="5"
                      clearable
                      hide-details
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../utils/formRules'
  export default {
    name: 'BonificacaoCard',
    props: {
      value: {
        type: Array,
        required: true,
      },
      produtos: {
        type: Array,
        default: () => [],
      },
      outlined: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        rules,
        produtos_bonificado: [],
      }
    },
    computed: {
      returnFilterProduto () {
        const data = this.produtos.map(produto => {
          if (!this.value) {
            return {
              nome: produto.nome,
              id: produto.produto_unidade_negocio_id,
              codigo: produto.codigo,
              quantidade: '',
              unidade: produto.unidade_sigla,
              marca: produto.marca,
            }
          }
          if (!this.value.some(produtoFilter => produtoFilter.id === produto.id)) {
            return {
              nome: produto.nome,
              codigo: produto.codigo,
              id: produto.produto_unidade_negocio_id,
              quantidade: '',
              unidade: produto.unidade_sigla,
              marca: produto.marca,
            }
          }
        })
        return data
      },
    },
    methods: {
      validate () {
        return this.$refs.boniForm.validate()
      },
      resetValidation () {
        return this.$refs.regrasForm.resetValidation()
      },
      addProdutoBonificado () {
        this.produtos_bonificado.forEach(produto => {
          this.value.push(produto)
        })
        this.produtos_bonificado = []
      },
      delProduto (index) {
        this.value.splice(index, 1)
      },
      filterProduto (item, consulta, nome) {
        if (nome.toLowerCase().includes(consulta.toLowerCase())) return true
        if (item.codigo.includes(consulta)) return true
        return false
      },
    },
  }
</script>
