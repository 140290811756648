<template>
  <v-dialog
    v-model="dialog"
    max-width="1192px"
    width="1192px"
    scrollable
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    style="height: 662px !important; max-height: 662px !important;"
  >
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title class="headline">
          {{ loading ? '' : formTitle }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        outlined
        :class="$vuetify.breakpoint.xs ? 'px-0' : '' "
      >
        <base-loading v-if="loading" />
        <div
          v-else
        >
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-form
                  ref="form"
                  dense
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-card
                        :elevation="4"
                        min-height="450px"
                        width="760px"
                        fluid
                      >
                        <v-card-text>
                          <v-row>
                            <v-col
                              cols="12"
                            >
                              <v-radio-group
                                v-model="tipo"
                                class="mt-0"
                                hide-details
                                row
                                @change="CLEAR_REGRAS"
                              >
                                <template v-slot:label>
                                  <div><strong>Tipo de Promoção</strong></div>
                                </template>
                                <v-radio value="campanha">
                                  <template v-slot:label>
                                    <div><strong class="primary--text">Campanha</strong></div>
                                  </template>
                                </v-radio>
                                <v-radio value="oferta">
                                  <template v-slot:label>
                                    <div><strong class="error--text">Oferta</strong></div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              md="3"
                            >
                              <data-field
                                v-model="data_inicio"
                                label="Data inicial*"
                                :data-min="minDate"
                                :data-max="data_fim"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              md="3"
                            >
                              <data-field
                                v-model="data_fim"
                                label="Data final*"
                                :data-min="data_inicio"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="4"
                              md="3"
                            >
                              <v-text-field
                                v-model="entrega"
                                v-mask="'##'"
                                outlined
                                dense
                                hide-details
                                autocomplete="off"
                                label="Entrega*"
                                suffix="Dias"
                                :rules="[rules.required, rules.numeric, rules.geq(1, 'Entrega deve ser maior que zero')]"
                                validate-on-blur
                                rounded
                              />
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-autocomplete
                                v-model="unidades"
                                :items="loadedUnidades"
                                item-text="nome_fantasia"
                                item-value="id"
                                return-object
                                chips
                                dense
                                multiple
                                attach
                                deletable-chips
                                small-chips
                                label="Unidade de negócio*"
                                clearable
                                :loading="!unidades"
                                :rules="[rules.minLength(1, '* Obrigatório')]"
                                autocomplete="off"
                                validate-on-blur
                                outlined
                                rounded
                                @change="verificarProdutos"
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-chip
                                    v-if="index < 4"
                                    rounded
                                    small
                                    class="caption"
                                  >
                                    <span>
                                      {{ item.nome_fantasia }}
                                    </span>
                                  </v-chip>
                                  <span
                                    v-if="index === 4"
                                    class="grey--text caption"
                                  >
                                    (+{{ unidades.length - 4 }} outros)
                                  </span>
                                </template>

                                <template
                                  slot="item"
                                  slot-scope="data"
                                >
                                  <v-list-item-content
                                    style="max-height: 100px"
                                    class="overflow-y-auto"
                                  >
                                    <v-list-item-title>{{ data.item.nome_fantasia }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-text-field
                                v-model="nome"
                                dense
                                autocomplete="nope"
                                :rules="[rules.required]"
                                label="Nome*"
                                validate-on-blur
                                outlined
                                rounded
                              />
                              <v-textarea
                                v-if="tipo === 'campanha'"
                                v-model="descricao"
                                label="Descrição"
                                validate-on-blur
                                rows="4"
                                no-resize
                                outlined
                                rounded
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="auto"
                      md="4"
                    >
                      <v-card
                        :elevation="4"
                        height="450px"
                      >
                        <v-container fluid>
                          <crud-image-uploader
                            ref="imageUploader"
                            v-model="imagem"
                            dialog-title="Foto do produto"
                            no-data="img/ofertaImage.png"
                            class=""
                            max-hei="800px"
                            max-wid="350px"
                            height="420px"
                            width="300px"
                            @delete="deleteImg()"
                          />
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="2">
              <v-col>
                <v-data-iterator
                  :items="regras"
                  hide-default-footer
                  no-data-text="Ainda não existem regras, crie uma em 'Nova Regra'."
                  style="overflow-y: auto;"
                >
                  <template v-slot:header>
                    <v-toolbar flat>
                      <v-toolbar-title style="text-align: center; font-weight: bold;">
                        {{ nome }}
                      </v-toolbar-title>
                      <v-spacer />

                      <v-btn
                        color="success"
                        dark
                        class="text-none mb-2 text-white"
                        rounded
                        @click="NEW_REGRA({ regras })"
                      >
                        <v-icon
                          dark
                          left
                        >
                          mdi-cube-unfolded
                        </v-icon>
                        Nova Regra
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template>
                    <regra-cards
                      v-for="(regra, index) in regras"
                      :key="index"
                      ref="regraCardsForm"
                      :unidade="unidades"
                      :regra="regra"
                      :index-regra="index"
                    />
                  </template>
                </v-data-iterator>
              </v-col>
            </v-window-item>
          </v-window>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="end"
          dense
        >
          <v-col cols="auto">
            <v-btn
              class="text-none text-white"
              color="error"
              rounded
              @click="cancel"
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="text-none text-white"
              color="primary"
              depressed
              rounded
              @click="proximoback"
            >
              <v-icon
                v-if="step === 2"
                dark
                left
              >
                mdi-arrow-left-bold
              </v-icon>

              {{ step === 1 ? 'Próximo' : ' Anterior ' }}
              <v-icon
                v-if="step === 1"
                dark
                right
              >
                mdi-arrow-right-bold
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="text-none text-white"
              color="success"
              depressed
              :disabled="!(step === 2)"
              rounded
              @click="submit"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { BACK, CLOSE, NEXT, SET_STATE, SUBMIT, NEW_REGRA, CLEAR_REGRAS } from '@/store/modules/forms/oferta'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
  import RegraCards from './RegraCards.vue'

  export default {
    name: 'OfertaForm',
    components: {
      RegraCards,
    },
    data () {
      return {
        produtos: [],
        todasUnidades: [],
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/oferta', ['loading', 'loadedUnidades', 'isEditing', 'rules', 'dialog']),
      ...mapFields('form', [
        'data.id',
        'data.tipo',
        'data.unidades',
        'data.nome',
        'data.descricao',
        'data.entrega',
        'data.data_inicio',
        'data.data_fim',
        'data.imagem',
      ]),
      ...mapFields('form/oferta', [
        'step',
      ]),
      ...mapMultiRowFields('form', [
        'data.regras',
      ]),
      minDate () {
        const dtNow = new Date()
        dtNow.setHours(0, 0, 0, 0)
        if (this.isEditing) {
          let di = this.data_inicio.slice(0, 10).split('-')
          di = new Date(parseInt(di[0]), parseInt(di[1]) - 1, parseInt(di[2]))
          di.setHours(0, 0, 0, 0)
          let dtMin
          if (dtNow > di) {
            dtMin = di.toISOString().slice(0, 10).split('-')
          } else {
            dtMin = dtNow.toISOString().slice(0, 10).split('-')
          }
          return (new Date(parseInt(dtMin[0]), parseInt(dtMin[1]) - 1, parseInt(dtMin[2]))).toISOString().slice(0, 10)
        } else {
          return dtNow.toISOString().slice(0, 10)
        }
      },
      isLastPage () {
        return this.step === 2
      },
      actionTextButton () {
        return this.isLastPage ? (this.isEditing ? 'Salvar' : 'Cadastrar') : 'Próximo'
      },
      controlTextButton () {
        return this.isLastPage ? 'Voltar' : 'Cancelar'
      },
      formTitle () {
        return !this.isEditing
          ? `Cadastrar ${this.tipo}`
          : `Editar ${this.tipo}`
      },
    },
    methods: {
      ...mapMutations('form/oferta', [BACK, CLOSE, SET_STATE]),
      ...mapActions('form/oferta', [SUBMIT, NEXT, NEW_REGRA, CLEAR_REGRAS]),
      submit () {
        if (!this.$refs.form.validate() || !this.validateRegras()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        } else if (this.isLastPage) {
          this.$refs.regraCardsForm.forEach(item => {
            item.resetValidation()
          })
          return this.SUBMIT()
        }
        return null
      },
      proximoback () {
        if (this.isLastPage) {
          this.BACK()
        } else {
          if (!this.$refs.form.validate()) {
            this.snackbar = {
              show: true,
              message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
            }
            return null
          } else if (!this.imagem && this.tipo === 'campanha') {
            this.snackbar = {
              show: true,
              message: 'Selecione uma imagem para a campanha!',
            }
            return null
          }
          this.NEXT()
        }
      },
      cancel () {
        this.CLOSE()
      },
      deleteImg () {
        this.imagem = null
      },
      verificarProdutos () {
        this.regras.forEach(regra => {
          const novosProdutos = regra.produtos.filter(produto => {
            if (produto.unidades.some(item => this.unidades.map(unidade => unidade.id).includes((typeof item === 'object') ? item.id : item))) {
              return produto
            }
          })
          regra.produtos = novosProdutos
        })
      },
      validateRegras () {
        let valido = true
        this.$refs.regraCardsForm.forEach(item => {
          if (!item.validate()) {
            valido = false
          }
        })
        return valido
      },
    },
  }
</script>import { includes } from 'lodash'
